import { useState, useEffect } from "react"
import { db } from "../../../firebase/firebase"
import { query, collection, where, getDocs, orderBy } from "firebase/firestore"
import ReactPlayer from "react-player/lazy"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay, faCirclePlay } from "@fortawesome/free-solid-svg-icons"
import "./VideoDisplay.css"

function VideoDisplay({ category }) {
  // using useParams to get the categoryname

  const [videoURL, setVideoURL] = useState()
  const [videos, setVideos] = useState([])

  // if the user is not logged in useEffect will redirect to the home page otherwise it will call getAllVideos function
  useEffect(() => {
    const getAllVideos = async () => {
      try {
        const q = query(
          collection(db, "videos"),
          where("category", "==", category)
        )
        const querySnapshot = await getDocs(q)

        const foundVideos = querySnapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id }
        })

        console.log(foundVideos, "FOUND")
        foundVideos.sort((a, b) => a.order - b.order)

        setVideos(foundVideos)
      } catch (error) {
        console.log(error)
      }
    }

    getAllVideos()
  }, [category])

  console.log(videos, "VIDZZ")

  // gets all the videos for the coorect category, useing useParms to get the categoryNames, then sets videos state to the return array

  return (
    <div className="col-12 ">
      <div className="d-flex flex-column flex-xl-row pt-3 p-xl-4">
        <div className=" col-xl-6">
          {videoURL === undefined ? (
            <div className="playerCon d-flex flex-column justify-content-center align-items-center">
              <FontAwesomeIcon className="playIcon" icon={faCirclePlay} />
              Choose a video to start.
            </div>
          ) : (
            <div className=" playerCon col-12 ">
              <ReactPlayer
                url={videoURL}
                controls={true}
                className="player"
                height={"100"}
                width={"100%"}
                playing
              />
            </div>
          )}
        </div>

        <div className="col-xl-6 detailsCon p-2 ">
          {videos.map((video) => (
            <div
              key={video.id}
              className="d-flex align-items-center p-2 itemCon mb-2"
            >
              <div className="col-10 detailsName ">{video.name}</div>

              <div className="col-2 d-flex   justify-content-end">
                <button
                  onClick={() => setVideoURL(video.link)}
                  className="playBtn"
                >
                  <FontAwesomeIcon
                    className="me-2"
                    icon={faPlay}
                    onClick={() => setVideoURL(video.link)}
                  />
                  PLAY
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default VideoDisplay
