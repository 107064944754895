import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { db } from "../../../firebase/firebase"
import { addDoc, collection } from "firebase/firestore"

import "react-datepicker/dist/react-datepicker.css"
import "./CheckInPage.css"
import { useAuthContext } from "../../../hooks/useAuthContext"

import NewTextarea from "../components/NewTextarea"

function CheckinPage() {
  let navigate = useNavigate()
  const { user } = useAuthContext()
  const [fields, setFields] = useState({
    date: new Date(),
    tasks: "",
    need: "",
    plan: "",
  })

  // adds the checkin to the checkins collection in db using the user UID as user id,
  const saveCheckin = async () => {
    try {
      await addDoc(collection(db, "checkins"), {
        fields,
        userId: user.uid,
      })
      setFields({
        date: new Date(),
        tasks: "",
        need: "",
        plan: "",
      })
      navigate("/profile")
    } catch (error) {
      console.log(error)
    }
  }

  // updates fields when user fills out form
  const handleFieldChange = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value })
  }

  return (
    <div className="d-flex flex-column align-items-center py-2 checkinCon">
      <h1 className="pageTitle d-lg-none text-center mb-3">Check In</h1>
      <h3 className="primaryText my-3">{fields.date.toLocaleDateString()}</h3>
      <h5 className="primaryText mb-3 p-3 text-center col-xxl-6">
        Each week I encourage you to reflect on what you’ve done and what you
        may need to do going forward. It also helps me see how you are getting
        on with tasks.
      </h5>
      <div className="col-xxl-6">
        {/* <section>
          <DatePicker
            disabled
            className="form-control"
            name="dateTime"
            onChange={(date) => setFields({ ...fields, date: date })}
            selected={fields.date}
          />
        </section> */}

        <NewTextarea
          label="What tasks did you do, or what did you accomplish?"
          handleFieldChange={handleFieldChange}
          name="tasks"
          value={fields.tasks ?? ""}
        />

        <NewTextarea
          label="What didn't go so well this week, or where do you need support?"
          handleFieldChange={handleFieldChange}
          name="need"
          value={fields.need ?? ""}
        />

        <NewTextarea
          label="What are your marketing plans for next week, what are you going to
              get done?"
          handleFieldChange={handleFieldChange}
          name="plan"
          value={fields.plan ?? ""}
        />

        <section className="d-flex justify-content-end me-3">
          <button className="primaryBtn" onClick={saveCheckin}>
            SAVE
          </button>
        </section>
      </div>
    </div>
  )
}

export default CheckinPage
