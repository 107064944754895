import { useEffect,  useState } from "react"
import { storage, db } from "../../../firebase/firebase"
import { collection, getDocs } from "firebase/firestore"
import { ref, getDownloadURL } from "firebase/storage"
import "./Downloads.css"

function Downloads() {

  const [downloads, setDownloads] = useState([])

  // if user is not logged in useEffect redirect user to the homepage
  // if user is logged in useEffect calls getAllDownloads function
  useEffect(() => {
    getAllDownloads()
  }, [])

  // the function gets all the entries of the downloads collection in the database, and sets the return data to the downloads array
  const getAllDownloads = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "downloads"))
      const listOfDownloads = querySnapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id }
      })
      setDownloads(listOfDownloads)
    } catch (error) {
      console.log(error)
    }
  }

  // the function gets the doc which needs to be downloaded using its id which was set to be the button id
  const downloadFile = (e) => {
    const gsReference = ref(storage, `downloads/${e.target.id}`)

    getDownloadURL(gsReference)
      .then((url) => {
        window.open(url, "_blank")
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // download material folder is in the public folder
  // downloads being mapped through and their details are being displayed , the download buttons ID is the id of the document to download
  return (
    <div>
      <h1 className="pageTitle d-md-none text-center">Downloads</h1>
      <div className="downloadCon">
        <div className="d-flex flex-column align-items-center py-5">
          {downloads?.map((download) => (
            <section className="col-11 col-lg-6 downloadSectionCon p-3 mb-3">
              <h2 className="primaryTitle mb-3">{download.name}</h2>
              <div className="blurbCon shadow p-2 mb-3">
                <p className="primaryText">{download.blurb}</p>
              </div>

              <div className="d-flex justify-content-end">
                <button
                  id={download.filename}
                  className="primaryBtn"
                  onClick={downloadFile}
                >
                  DOWNLOAD
                </button>
              </div>
            </section>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Downloads
