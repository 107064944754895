function CheckinModal ({onClose, show, checkin}) {

    // if show === false the component doesnt render the modal
    if(!show){
        return false
    }

    // checkinModal is rendered in checkins => components/admin/company/checkins/Checkins.js
    return (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Checkin </h4>
                    <button className="close-Btn" onClick={onClose}>x</button>
                </div>
                <div className="modal-body">
                <section>
                <h3>
                    What tasks did you do, or what did you accomplish?
                </h3>
                <p>
                    {checkin.fields.tasks}
                </p>
            </section>
            <section>
                <h3>
                    What didn't go so well this week, or where do you need support?                
                </h3>
                <p>
                    {checkin.fields.need}
                </p>
            </section>
            <section>
                <h3>
                    What are your marketing plans for next week, what are you going to get done?                
                </h3>
                <p>
                    {checkin.fields.plan}
                </p>
            </section>
                </div>
            </div>
        </div>
    )
}

export default CheckinModal