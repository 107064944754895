import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { db } from "../../../../firebase/firebase"
import {
  getDoc,
  doc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore"
import Modal from "../../modals/Modal"
import ActionPlanModal from "../../modals/actionplan/ActionPlanModal"
import ActionPlans from "../actionplans/ActionPlans"
import AccessToggle from "../../accesstoggle/AccessToggle"
import Checkins from "../checkins/Checkins"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons"
import "./style.css"
import CompanyDetailsRow from "./CompanyDetailsRow"
import ViewQuestionnaire from "./ViewQuestionnaire"

function CompanyProfile() {
  const companyId = useParams().companyId
  const [company, setCompany] = useState()
  const [questionnaire, setQuestionnaire] = useState()
  const [showActionPlan, setShowActionPlan] = useState(false)

  // once on the dashboard admin select the company which they wish to view, CompanyProfile compoent is being rendered
  // on the first render:
  //-> getCompany function: finds the correct company in the database and sets company state
  // -> getQuestionnaire function finds the questionnaire filled out by the chosen company and sets it as state
  useEffect(() => {
    const getCompany = async () => {
      const docRef = doc(db, "users", companyId)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        setCompany(docSnap.data())
      } else {
        console.log("Can not find the company!")
      }
    }

    const getQuestionnaire = async () => {
      const q = query(
        collection(db, "questionnaires"),
        where("userId", "==", companyId)
      )
      const querySnapshot = await getDocs(q)

      const foundQuestionnaire = querySnapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id }
      })
      setQuestionnaire(foundQuestionnaire[0])
    }

    getCompany()
    getQuestionnaire()
  }, [companyId])

  console.log(questionnaire, "QQQ")

  return (
    <div className="">
      <div className="container">
        <section className="cprof-access-container">
          <AccessToggle />
        </section>
        <div className="d-flex flex-column ">
          <section className="d-flex flex-column ">
            <h2 className="primaryTitle">Member's details:</h2>
            <div className="px-lg-4 col-8">
              <CompanyDetailsRow label={"Name"} field={company?.name} />
              <CompanyDetailsRow label={"Owner"} field={company?.name} />
              <CompanyDetailsRow label={"Email"} field={company?.email} />
              <CompanyDetailsRow
                label={"Questionnaire"}
                field={<ViewQuestionnaire questionnaire={questionnaire} />}
              />
            </div>
          </section>
        </div>

        <section className="cprof-actionp-container">
          <div className="cprof-actionp-top">
            <h2 className="primaryTitle">Action plans</h2>

            <FontAwesomeIcon
              icon={faSquarePlus}
              onClick={() => setShowActionPlan(true)}
              className="add-actionp-icon"
            />
          </div>
          {/* <ActionPlanModal
          onClose={() => setShowActionPlan(false)}
          show={showActionPlan}
        /> */}
          <ActionPlans />
        </section>
        <section className="cprof-checkin-container">
          <h2 className="primaryTitle">Check Ins</h2>
          <Checkins />
        </section>

        {showActionPlan && (
          <Modal>
            <ActionPlanModal setShowActionPlan={setShowActionPlan} />
          </Modal>
        )}
      </div>
    </div>
  )
}
export default CompanyProfile
