import React from "react"
import ReactDOM from "react-dom/client"

//Package styles
import "bootstrap/dist/css/bootstrap.min.css"
import "./index.css"
import App from "./App"
import { BrowserRouter as Router } from "react-router-dom"
import reportWebVitals from "./reportWebVitals"

import { AuthContextProvider } from "./context/AuthContext"

// wrapping App in BrowserRouter to be able to use routing(react-router-dom) throughout the app
const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
