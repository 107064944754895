import { useEffect, useState, useContext } from "react"
import UserContext from "../../../contexts/userContext"
import { useParams, useNavigate } from "react-router-dom"
import { auth, db } from "../../../firebase/firebase"
import { createUserWithEmailAndPassword } from "firebase/auth"
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore"
import "../forms.css"
import { Form } from "react-bootstrap"
import { useAuthContext } from "../../../hooks/useAuthContext"

function RegisterByInvite() {
  const navigate = useNavigate()
  const params = useParams()
  const invitationId = params.inviteCode
  const { dispatch } = useAuthContext()
  const [invitee, setInvitee] = useState()
  const [dataByUser, setDataByUser] = useState({
    name: "",
    password: "",
    passwordCheck: "",
  })
  const [alert, setAlert] = useState()

  // on render useEffect finds the invitation in the database invitations collection (using invitationID, from parameters => useParams), and checks if the invitation has been used already or not, if yes sets the alert state to an error message, if the invitation has not been used yet, useEffect sets the invitee to the returned data (email,name)
  useEffect(() => {
    getDoc(doc(db, "invitations", invitationId)).then((docSnap) => {
      if (docSnap.exists()) {
        docSnap.data().invitationRedeemed === true
          ? setAlert("The invitation has been used already!")
          : setInvitee(docSnap.data())
      }
    })
  }, [invitationId])

  // sets the data typed in by the user onchange, -> when user fills the form
  const handleFieldChange = (event) => {
    setDataByUser({ ...dataByUser, [event.target.name]: event.target.value })
  }

  // first checks if password match
  // creates a user with password and email in firebase auth
  // creates a user with all credentials (company, name, email, isAdmin, hasAccess) in users collection in firestire database
  // sets the usercontext to the users details
  // as the last step sets the invitation to used and navigates the user to their profile
  const handleRegister = () => {
    if (dataByUser.password === dataByUser.passwordCheck) {
      //create user in firebase
      createUserWithEmailAndPassword(auth, invitee.email, dataByUser.password)
        .then((userCredentials) => {
          setDoc(doc(db, "users", userCredentials.user.uid), {
            company: invitee.company,
            name: dataByUser.name,
            email: invitee.email,
            isAdmin: false,
            hasAccess: true,
          })
            .then(() => {
              getDoc(doc(db, "users", userCredentials.user.uid))
                .then((docSnap) => {
                  if (docSnap.exists()) {
                    const data = docSnap.data()
                    dispatch({
                      type: "LOGIN",
                      payload: {
                        ...data,
                        uid: userCredentials.user.uid,
                      },
                    })
                  }
                })
                .catch((error) => console.log(error))
            })
            .catch((error) => console.log(error))
          const invitationRef = doc(db, "invitations", invitationId)
          updateDoc(invitationRef, {
            invitationRedeemed: true,
          })
          navigate("/profile")
        })
        .catch((error) => console.log(error))
    }
  }

  return (
    <>
      {alert ? (
        alert
      ) : (
        <div className="d-flex justify-content-center videosBottomRow p-5">
          <div className="d-flex flex-column col-md-6 ">
            <h3 className="primaryTitle text-center">
              Please complete your registration!
            </h3>
            <p className="reg-form-field">{invitee?.company}</p>
            <p className="reg-form-field">{invitee?.name}</p>
            <p className="reg-form-field">{invitee?.email}</p>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Enter your name..."
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Enter your password..."
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Re-type password</Form.Label>
                <Form.Control
                  type="password"
                  name="passwordCheck"
                  placeholder="Re-type your password..."
                  onChange={handleFieldChange}
                />
              </Form.Group>
            </Form>
            <div>
              <button onClick={handleRegister} className="primaryBtn">
                Register
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default RegisterByInvite
