import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom"
import { useEffect } from "react"
import { auth, db } from "./firebase/firebase"
import { onAuthStateChanged } from "firebase/auth"
import { doc, getDoc } from "firebase/firestore"
import { useAuthContext } from "./hooks/useAuthContext"

//styling sheet for setting font colour
import "./App.css"

import Home from "./components/home/Home"
import RegisterByInvite from "./components/auth/register/RegisterByInvite"
import Profile from "./components/member/profile/Profile"
import Dashboard from "./components/admin/dashboard/Dashboard"
import Questionnaire from "./components/member/questionnaire/Questionnaire"
import CompanyProfile from "./components/admin/company/companyprofile/CompanyProfile"
import ViewActionPlans from "./components/member/viewactionplans/ViewActionPlans"
import CheckinPage from "./components/member/checkinpage/CheckinPage"
import Downloads from "./components/member/downloads/Downloads"
import Templates from "./components/member/templates/Templates"
import Courses from "./components/member/courses/Courses"
import TopTipVideos from "./components/member/toptipvideos/TopTipVideos"
import VideoDisplay from "./components/member/videoDisplay/VideoDisplay"
import TopNav from "./components/Navigation/TopNav"
import Footer from "./components/member/footer/Footer"

function App() {
  const { user, dispatch } = useAuthContext()
  // setting the userContext on the first render
  // check if user is logged in
  useEffect(() => {
    // Adds an observer for changes to the user's sign-in state. if the user is logge in userCnotext is set tot he details of the user

    const existingUser = JSON.parse(localStorage.getItem("user"))

    if (existingUser) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid
          getDoc(doc(db, "users", uid))
            .then((docSnap) => {
              if (docSnap.exists()) {
                const data = docSnap.data()
                dispatch({
                  type: "LOGIN",
                  payload: {
                    ...data,
                    uid: uid,
                  },
                })
              }
            })
            .catch((error) => console.log(error))
        } else {
          dispatch({ type: "LOGOUT", payload: {} })
        }
      })
    }
  }, [])

  return (
    <BrowserRouter>
      <div className="App">
        <section>
          <TopNav />
        </section>
        {!user && (
          <Routes>
            <Route
              exact
              path="*"
              element={<Navigate to={"/"} />}
            ></Route>
            <Route exact path="/" element={<Home />}></Route>
            <Route
              exact
              path="/registerByInvite/:inviteCode"
              element={<RegisterByInvite />}
            ></Route>
          </Routes>
        )}
        {user && (
          <Routes>
            <Route
              exact
              path="/"
              element={
                !user.isAdmin ? <Profile /> : <Navigate to={"/dashboard"} />
              }
            ></Route>

            <Route
              exact
              path="/dashboard"
              element={user.isAdmin ? <Dashboard /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              exact
              path="/company/:companyName/:companyId"
              element={<CompanyProfile />}
            ></Route>
            <Route exact path="/profile" element={<Profile />}></Route>
            <Route
              exact
              path="/:userId/questionnaire"
              element={<Questionnaire />}
            ></Route>
            <Route
              exact
              path="/:userId/actionplans"
              element={<ViewActionPlans />}
            ></Route>
            <Route
              exact
              path="/:userId/checkin"
              element={<CheckinPage />}
            ></Route>
            <Route
              exact
              path="/:userId/downloads"
              element={<Downloads />}
            ></Route>
            <Route
              exact
              path="/:userId/templates"
              element={<Templates />}
            ></Route>
            <Route exact path="/:userId/courses" element={<Courses />}></Route>
            <Route
              exact
              path="/:userId/toptipvideos"
              element={<TopTipVideos />}
            ></Route>
            <Route
              exact
              path="/:userId/:categoryname/videodisplay"
              element={<VideoDisplay />}
            ></Route>
          </Routes>
        )}
        {user && (
          <section className="footer">
            <Footer />
          </section>
        )}
      </div>
    </BrowserRouter>
  )

  // userContext with all the logeed in user's details is available throught all component using context API
}

export default App
