import React from "react"

export default function CategoryListItem({
  title,
  slug,
  setCategory,
  category,
}) {
  //EG title = Social Media
  //slug = socialmedia

  return (
    <div className="d-flex flex-column justify-content-center">
      <button
        className={`secondaryBtn ${category === slug ? "selectedCat" : ""}`}
        onClick={() => setCategory(`${slug}`)}
      >
        {title}
      </button>
    </div>
  )
}
