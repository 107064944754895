import { useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { auth, db } from "../../../firebase/firebase"
import { signInWithEmailAndPassword, signOut } from "firebase/auth"
import { doc, getDoc } from "firebase/firestore"

import { Form, FloatingLabel } from "react-bootstrap"
import "../forms.css"
import { useAuthContext } from "../../../hooks/useAuthContext"

function Login() {
  let navigate = useNavigate()
  const [form, setForm] = useState({ rememberMe: false })

  const [formErrors, setFormErrors] = useState("")
  const [loginErr, setLoginErr] = useState("")

  const { user, dispatch } = useAuthContext()

  // the user is being signed in with email and password
  // then using the uid the login function finds the user in firestore database and if the user is admin it sets its credentials to the userContext and navigates to the dashboard, if the user is NOT an admin and the hasAccess key's value is true the function sets their credentials to the context and navigates the user to their profile
  // if the user is not an admin, and they dont have access to the site (hasAccess === false) the function signs the user out and redirects them to the home page (there could be alert message implemented here)
  const login = (e) => {
    e.preventDefault()

    if (!form.email) {
      setLoginErr("Email is required")
      return
    }
    if (!form.password) {
      setLoginErr("Password is required")
      return
    }

    signInWithEmailAndPassword(auth, form.email, form.password)
      .then((userCredentials) => {
        getDoc(doc(db, "users", userCredentials.user.uid))
          .then((docSnap) => {
            if (docSnap.exists()) {
              // setUserData(docSnap.data())
              if (docSnap.data().isAdmin === true) {
                // navigate("/dashboard")
                const data = docSnap.data()
                dispatch({
                  type: "LOGIN",
                  payload: {
                    ...data,
                    uid: userCredentials.user.uid,
                  },
                })
              } else {
                if (docSnap.data().hasAccess === true) {
                  const data = docSnap.data()
                  dispatch({
                    type: "LOGIN",
                    payload: {
                      ...data,
                      uid: userCredentials.user.uid,
                    },
                  })
                } else {
                  dispatch({
                    type: "LOGOUT",
                    payload: {},
                  })
                  signOut(auth).then(navigate("/"))
                }
              }
            }
          })
          .catch((error) => setLoginErr("Email or password incorrect."))
      })
      .catch((error) => setLoginErr("Email or password incorrect."))
  }

  /*The setField Function
        - updates the user's details as they type in, this rerenders the page every single time when user presses a key
    */

  const setField = (field, value) => {
    setLoginErr("")
    setForm({
      ...form,
      [field]: value,
    })
  }

  return (
    <>
      <h1 className="login-form-title mb-3">
        Welcome to the Acorn Marketing Academy
      </h1>
      <h5 className="login-form-subtitle mb-3">Log in and let’s get cracking!</h5>
      <Form onSubmit={login} className="mb-3">
        <Form.Group>
          <FloatingLabel
            controlId="floatingInput"
            label="Email address"
            className="mb-3"
          >
            <Form.Control
              className="form-input"
              type="email"
              placeholder="name@example.com"
              isInvalid={formErrors.email}
              onChange={(e) => setField("email", e.target.value)}
            />
            <Form.Control.Feedback className="text-danger" type="invalid">
              {formErrors.email}
            </Form.Control.Feedback>
          </FloatingLabel>
          <FloatingLabel controlId="floatingPassword" label="Password">
            <Form.Control
              className="form-input"
              type="password"
              placeholder="Password"
              isInvalid={formErrors.password}
              onChange={(e) => setField("password", e.target.value)}
            />
            <Form.Control.Feedback className="text-danger" type="invalid">
              {formErrors.password}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="d-flex justify-content-between align-items-center mt-3">
          <Form.Check
            className="text-black"
            type="checkbox"
            label={`Stay logged in?`}
            onChange={(e) => setField("rememberMe", e.target.checked)}
          />
          <button onClick={login} className="form-btn">
            Login
          </button>
        </Form.Group>
      </Form>

      {loginErr && (
        <span className="loginErr  text-center text-danger p-3">
          {loginErr}
        </span>
      )}
    </>
  )
}

export default Login
