// Import the functions you need from the SDKs you need
import { initializeApp, getApps, getApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getFunctions } from "firebase/functions"
import { getStorage } from "firebase/storage"

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBe0aP0VxdZGrRkRMbnj_5IXSTh3xPbLe0",
  authDomain: "acorn-m.firebaseapp.com",
  projectId: "acorn-m",
  storageBucket: "acorn-m.appspot.com",
  messagingSenderId: "493819126748",
  appId: "1:493819126748:web:4f364754bf17ca4382e519",
}

// Initialize Firebase
let app
if (getApps().length === 0) {
  // if app not initialised yet, create one
  app = initializeApp(firebaseConfig)
} else {
  // if app already initialised, dont create a new, use the app
  app = getApp()
}

// initialize firebase authentication (to authenticate and authorise users, register, login, sign out)
const auth = getAuth(app)

// initialize Firestore database(storing all data of users, videos, checking, templates, downloads, tasks(actionplans), questionnaires, invitations )
const db = getFirestore(app)

// initalize Cloud functions (for sendMail function)
const functions = getFunctions(app)

// initialize storage space, storage used for storing the downloads and templates
const storage = getStorage(app)

export { auth, db, functions, storage }
