import { db } from "../../../../firebase/firebase"
import { updateDoc, doc } from "firebase/firestore"

function Task({ task }) {
  // finds task in the db and updates completed key's value to the opposite as it was before click =>  true will be false, false will be true
  const updateTask = async () => {
    const taskRef = doc(db, "tasks", task.id)
    await updateDoc(taskRef, {
      completed: !task.completed,
    })
  }

  return (
    <tr className="tRow">
      <td>{task.task}</td>
      <td>{new Date(task.duedate.toDate()).toLocaleDateString()}</td>
      <td>
        <div className="checkbox mt-1">
          <input
            type="checkbox"
            id={task.id}
            defaultChecked={task.completed === true ? true : false}
            onChange={updateTask}
          />
          {/* <label htmlFor={task.id}></label> */}
        </div>
      </td>
    </tr>
  )
}
export default Task
