import React from "react"

export default function ViewQuestionnaire({ questionnaire }) {
  return (
    <div>
      <div>
        <div>
          <div>
            {questionnaire === undefined ? (
              <p>The member did not answer the questions yet!</p>
            ) : (
              <div>
                <section>
                  <p className="primaryTitle">
                    What social media do you use for business and what are your
                    account profiles, so I can check out what you're up to.
                  </p>
                  <div className="d-flex">
                    <p className="primaryText me-2">Instagram:</p>
                    <p>{questionnaire.instagram}</p>
                  </div>
                  <div className="d-flex">
                    <p className="primaryText me-2">Twitter:</p>
                    <p>{questionnaire.twitter}</p>
                  </div>
                  <div className="d-flex">
                    <p className="primaryText me-2">LinkedIn:</p>
                    <p>{questionnaire.linkedin}</p>
                  </div>
                  <div className="d-flex">
                    <p className="primaryText me-2">YouTube:</p>
                    <p>{questionnaire.youtube}</p>
                  </div>
                  <div className="d-flex">
                    <p className="primaryText me-2">TikTok:</p>
                    <p>{questionnaire.tiktok}</p>
                  </div>
                </section>
                <section>
                  <p className="primaryTitle">
                    Tell me about your business, your products and services.
                  </p>
                  <p>{questionnaire.businessInfo}</p>
                </section>
                <section>
                  <p className="primaryTitle">
                    What marketing have your tried in the past?
                  </p>
                  <div className="d-flex">
                    <p className="primaryText me-2">What has worked:</p>
                    <p>{questionnaire.worked}</p>
                  </div>
                  <div className="d-flex">
                    <p className="primaryText me-2">What has failed:</p>
                    <p>{questionnaire.failed}</p>
                  </div>
                </section>
                <section>
                  <p className="primaryTitle">
                    What are you struggling with at the moment, or what is the
                    area you'd like to look at in more depth?
                  </p>
                  <p>{questionnaire.struggles}</p>
                </section>
                <section>
                  <p className="primaryTitle">
                    Can you tell me about your target customer?
                  </p>
                  <p>{questionnaire.targetCustomer}</p>
                </section>
                <section>
                  <p className="primaryTitle">
                    What are you unique selling points (USPs)?
                  </p>
                  <p>{questionnaire.usp}</p>
                </section>
                <section>
                  <p className="primaryTitle">
                    What would you like to achieve by working with me?
                  </p>
                  <p>{questionnaire.goals}</p>
                </section>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
