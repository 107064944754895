import { useNavigate } from "react-router-dom"
import { useAuthContext } from "../../../hooks/useAuthContext"
import "./Courses.css"
function Courses() {
  let navigate = useNavigate()
  const { user } = useAuthContext()

  return (
    <div className="d-flex flex-column ">
      <h1 className="pageTitle d-lg-none text-center mb-3">Courses</h1>
      <div className="d-flex col secondaryBg p-3 topRow">
        <section className="col col-lg-6 d-flex justify-content-center justify-content-md-end p-3 ">
          <div className="col-xxl-9 p-4 topRowCon ">
            <h3 className="primaryTitle mb-4">Mini Marketing Masterclasses</h3>
            <p className="primaryText mb-4">
              Watch my pre-recorded Mini Marketing Masterclasses here. I cover a
              whole range of topics about marketing and social media - get a pen
              and paper ready, delve in and get ready to learn.
            </p>
            {/* <ul className="secondaryText mb-4">
              <li className="mb-2 "></li>
              <li className="mb-2"></li>
              <li className="mb-2"></li>
            </ul> */}
            <p className="primaryText mb-4"></p>
            <div className=" d-flex justify-content-end">
              <button
                className="primaryBtn"
                onClick={() =>
                  navigate(`/${user.uid}/toptipvideos`, {
                    state: { category: "minimarketingclass" },
                  })
                }
              >
                Check it out
              </button>
            </div>
          </div>
        </section>
        <section className="d-none d-lg-flex col-lg-6 coursesImgCon p-3 ps-xl-5">
          <div className="coursesImg1"></div>
        </section>
      </div>

      <div className="d-flex col flex-row-reverse  bottomRow p-3">
        <section className="col col-lg-6 d-flex  p-3">
          <div className="col-xxl-9 p-2">
            <h3 className="primaryTitle mb-4">Marketing Mastery Programme</h3>
            <p className="primaryText mb-4">
              This is my signature programme, where you literally learn the step
              by step foundations of marketing for any business. Each session is
              2 hours long and includes supporting material. If you really want
              to up your marketing game and understand how to take your
              marketing to the next level, this has everything you need to know.
            </p>
            {/* <ul className="secondaryText mb-4">
              <li className="mb-2 "></li>
              <li className="mb-2"></li>
              <li className="mb-2"></li>
            </ul>
            <p className="primaryText mb-4"></p> */}
            <div className=" d-flex justify-content-end">
              <button
                className="primaryBtn"
                onClick={() =>
                  navigate(`/${user.uid}/toptipvideos`, {
                    state: { category: "masteryclass" },
                  })
                }
              >
                Check it out
              </button>
            </div>
          </div>
        </section>
        <section className="d-none d-lg-flex col-lg-6 coursesImgCon justify-content-md-end pe-xl-5 mt-3">
          <div className="coursesImg2"></div>
        </section>
      </div>
      {/* <div>
                <section className="courses-card">
                    <p className="courses-text">
                    Watch my pre-recorded Mini Marketing Masterclasses here. I cover a whole range of topics about marketing and social media - get a pen and paper ready, delve in and get ready to learn.
                    </p>
                    <Link to={`/${user.uid}/minimarketingclass/videodisplay`}>
                        Mini Marketing MasterClass
                    </Link>
                </section>
                <section className="courses-card">
                    <p className="courses-text">
                    This is my signature programme, where you literally learn the step by step foundations of marketing for any business. Each session is 2 hours long and includes supporting material. If you really want to up your marketing game and understand how to take your marketing to the next level, this has everything you need to know.
                    </p>
                    <Link to={`/${user.uid}/masteryclass/videodisplay`}>
                        Marketing Mastery Programme
                    </Link>
                </section>
            </div> */}
    </div>
  )
}
export default Courses
