import React from "react"

export default function CompanyDetailsRow({ label, field }) {
  return (
    <div className="d-flex flex-column flex-lg-row p-2">
      <div className="col-4">
        <h5 className="p-0 mb-2 primaryTitle">{label}:</h5>
      </div>
      <div className="col">
        {field}
      </div>
    </div>
  )
}
