import { useEffect, useState } from "react"

import { db } from "../../../firebase/firebase"
import { getDocs, query, where, collection } from "firebase/firestore"
import Task from "./task/Task"
import "./style.css"
import { useAuthContext } from "../../../hooks/useAuthContext"
import { Table } from "react-bootstrap"

function ViewActionPlans() {
  const { user } = useAuthContext()

  const [tasks, setTasks] = useState([])

  // if user is not logged in useEffect redirects to the homepage, otherwise it iwll call getAllTasks func
  useEffect(() => {
    const getAllTasks = async () => {
      try {
        const q = query(
          collection(db, "tasks"),
          where("userId", "==", user.uid)
        )
        const querySnapshot = await getDocs(q)
        const listOfTasks = querySnapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id }
        })
        setTasks(listOfTasks)
      } catch (error) {
        console.log(error)
      }
    }
    if (user) {
      getAllTasks()
    }
  }, [user])

  // gets all the tasks from the database where the userID === with the uid of the currently logged in user, to make sure they only get the tasks which was assigned to them and not to other companies.
  // then the function sends back an array of doc.data and sets this array to == tasks state

  // tasks array is being mapped through and Task component is being called for each of them with all the task details passed down as a props called task
  return (
    <div className="d-flex flex-column">
      <h1 className="pageTitle d-md-none text-center mb-3">Action Plan</h1>

      <div className="d-flex flex-column align-items-center justify-content-center apImage py-5 actionCon">
        <div className="col-11 col-lg-6 tableCon">
          <h5 className=" primaryText text-center mb-3 py-3">
            This section is where we can add in specific tasks for you to
            complete each time we meet. This will keep you on track and give you
            deadlines and accountability to get things done. <br /> If you get
            stuck please just let me know and we can work through any issues
            together.
          </h5>
          <Table hover>
            <thead>
              <tr className="tHead">
                <td>Task</td>
                <td>Due</td>
                <td>Completed</td>
              </tr>
            </thead>
            <tbody>
              {tasks?.length === 0 ? (
                <tr className="primaryTitle">
                  <td colSpan={3}>No Tasks Yet...</td>
                </tr>
              ) : (
                tasks.map((task) => <Task key={task.id} task={task} />)
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  )
}

export default ViewActionPlans
