import logo from "../../assets/images/logo.png"
import "../../assets/fonts/GoldenPlains.ttf"
import "../../assets/fonts/Montserrat-Light.ttf"
import "./home.css"
import Login from "../auth/login/Login"
import { useState } from "react"

function Home() {
  // home page to render welcome message and login button
  const [login, setLogin] = useState(false)

  return (
    <div className="d-flex flex-column homePage ">
      <div className="d-flex homeContent align-items-center">
        <div className="col-lg-6 mb-5"></div>
        <div className="col col-lg-6 p-3 d-flex justify-content-center">
          <div className="d-flex formTextCon p-3 text-center justify-content-center col-md-10">
            <div className="formCon p-3">
              <Login />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
