import React from "react"

export default function NewInput({ handleFieldChange, name, label, type }) {
  return (
    <section className="d-flex flex-column flex-lg-row  p-3">
      <label className="primaryTitle col-lg-4 px-2 mb-2">{label}</label>
      <input
        type={type ?? "text"}
        className="form-control"
        name={name}
        onChange={handleFieldChange}
      />
    </section>
  )
}
