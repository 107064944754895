import React from "react"
import { auth, db } from "../../firebase/firebase"
import { signOut } from "firebase/auth"
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap"
import { NavLink, useNavigate } from "react-router-dom"
import { useAuthContext } from "../../hooks/useAuthContext"
import "./TopNav.css"

export default function TopNav({ theme, setTheme }) {
  const { user, dispatch } = useAuthContext()
  const navigate = useNavigate()

  const handleLogout = () => {
    dispatch({
      type: "LOGOUT",
      payload: {},
    })
    signOut(auth)
    navigate("../")
  }

  return (
    <Navbar collapseOnSelect expand="lg" className=" topNav">
      <div className="container-fluid p-2">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <div className="d-md-flex col-12">
            <Nav className="col d-lg-flex flex-wrap justify-content-around">
              {user && user.isAdmin === false && (
                <>
                  <div className=" d-flex align-items-center">
                    <NavLink
                      className="navLink col p-2"
                      to={`/${user.uid}/questionnaire`}
                    >
                      Questionnaire
                    </NavLink>
                  </div>
                  <div className="d-flex align-items-center ">
                    <NavLink
                      className="navLink col p-2"
                      to={`/${user.uid}/actionplans`}
                    >
                      Action Plan
                    </NavLink>
                  </div>
                  <div className="d-flex  align-items-center ">
                    <NavLink
                      className="navLink col p-2"
                      to={`/${user.uid}/checkin`}
                    >
                      Check In
                    </NavLink>
                  </div>
                  <div className="d-flex align-items-center ">
                    <NavLink
                      className="navLink col p-2"
                      to={`/${user.uid}/downloads`}
                    >
                      Downloads
                    </NavLink>
                  </div>
                </>
              )}
              {user && user.isAdmin && (
                <>
                  <div className=" d-flex align-items-center">
                    <NavLink className="navLink col p-2" to={`/dashboard`}>
                      Admin Dashboard
                    </NavLink>
                  </div>
                </>
              )}
            </Nav>
            <div
              className="d-flex justify-content-center  d-none d-lg-flex col-2 p-3 logoCon "
              onClick={() => navigate("/")}
            >
              <img
                src="https://acorn-marketing.co.uk/wp-content/uploads/2022/05/logo.svg"
                className="navLogo"
              />
            </div>
            <Nav className="col d-md-flex flex-wrap justify-content-around">
              {user && user.isAdmin === false && (
                <>
                  <div className="d-flex align-items-center ">
                    <NavLink
                      className="navLink col p-2"
                      to={`/${user.uid}/templates`}
                    >
                      Templates
                    </NavLink>
                  </div>
                  <div className="d-flex align-items-center ">
                    <NavLink
                      className="navLink col p-2"
                      to={`/${user.uid}/courses`}
                    >
                      Courses
                    </NavLink>
                  </div>
                  <div className="d-flex align-items-center">
                    <NavLink
                      className="navLink col p-2 "
                      to={`/${user.uid}/toptipvideos`}
                    >
                      Videos
                    </NavLink>
                  </div>
                  <div className="d-md-flex align-items-center ">
                    <div
                      className="navLink col p-2"
                      onClick={() => handleLogout()}
                    >
                      Logout
                    </div>
                  </div>
                </>
              )}
              {user && user.isAdmin && (
                <>
                  <div className="d-md-flex align-items-center ">
                    <div
                      className="navLink col p-2"
                      onClick={() => handleLogout()}
                    >
                      Logout
                    </div>
                  </div>
                </>
              )}
            </Nav>
          </div>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}
