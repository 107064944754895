import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { db } from "../../../../firebase/firebase"
import { getDocs, collection, where, query } from "firebase/firestore"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons"
import "./style.css"

function ActionPlans() {
  const companyId = useParams().companyId
  const [tasks, setTasks] = useState()

  // getAllTasksofCompany function gets all the tasks which was assigned for the company by the admin, from the database on the first render, then sets tasks to equal the array
  useEffect(() => {
    getAllTasksOfCompany()
  }, [tasks])

  const getAllTasksOfCompany = async () => {
    try {
      const q = query(collection(db, "tasks"), where("userId", "==", companyId))
      const querySnapshot = await getDocs(q)

      const listOfTasks = querySnapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id }
      })
      setTasks(listOfTasks)
    } catch (error) {
      console.log(error)
    }
  }

  // tasks is being mapped through to display each task for the admin
  // ActionPlanModal is rendered in companyProfile => components/admin/company/companyProfile/CompanyProfile.js
  return (
    <table className="table">
      <thead>
        <tr>
          <td>
            <h5 className="primaryTitle">Task</h5>
          </td>
          <td>
            <h5 className="primaryTitle">Due Date</h5>
          </td>
          <td>
            <h5 className="primaryTitle">Complete</h5>
          </td>
        </tr>
      </thead>
      <tbody>
        {tasks?.map((task, index) => (
          <tr key={index}>
            <td>{task.task}</td>
            <td>{new Date(task.duedate.toDate()).toLocaleDateString()} </td>
            <td>
              {task.completed ? (
                <FontAwesomeIcon
                  className="actionplan-icon-done"
                  icon={faCircleCheck}
                />
              ) : (
                <FontAwesomeIcon
                  className="actionplan-icon-undone"
                  icon={faCircleXmark}
                />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ActionPlans
