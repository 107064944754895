import { useState } from "react"
import { useParams } from "react-router-dom"
import DatePicker from "react-datepicker"
import { db } from "../../../../firebase/firebase"
import { addDoc, collection, serverTimestamp } from "firebase/firestore"
import "../style.css"
import "react-datepicker/dist/react-datepicker.css"

function ActionPlanModal({ setShowActionPlan }) {
  const [task, setTask] = useState("")
  const [date, setDate] = useState(new Date())
  const companyId = useParams().companyId

  // using the show props, if show = false the companent is not rendered

  // addTask function is being called on button click, the task is being saved to the database tasks collection, using the companyId as user ID, company Id is from the parameters using useParams
  const addTask = async () => {
    await addDoc(collection(db, "tasks"), {
      task: task,
      duedate: date,
      userId: companyId,
      completed: false,
      timestamp: serverTimestamp(),
    })
    await setShowActionPlan(false)
  }

  return (
    <div className="p-2">
      <h4 className="mb-3 primaryTitle">Add New Action Plan</h4>
      <textarea
        placeholder="Type in a new task..."
        onChange={(e) => setTask(e.target.value)}
        className="form-control mb-3"
      />
      <section className="mb-3">
        <DatePicker
          name="dateTime"
          onChange={(date) => setDate(date)}
          value={date}
          selected={date}
          className="form-control"
        />
      </section>

      <div className="d-flex justify-content-between">
        <button
          className="btn btn-danger"
          onClick={() => setShowActionPlan(false)}
        >
          Cancel
        </button>
        <button className="btn btn-primary" onClick={addTask}>
          Add Task
        </button>
      </div>
    </div>
  )
}
export default ActionPlanModal
