import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { db } from "../../../firebase/firebase"
import { addDoc, collection } from "firebase/firestore"
import "./Questionnaire.css"
import { useAuthContext } from "../../../hooks/useAuthContext"
import NewTextarea from "../components/NewTextarea"
import NewInput from "../components/NewInput"

function Questionnaire() {
  const { user } = useAuthContext()
  let navigate = useNavigate()

  const [answers, setAnswers] = useState({
    address: "",
    birthday: "",
    website: "",
    instagram: "",
    twitter: "",
    linkedin: "",
    youtube: "",
    tiktok: "",
    worked: "",
    failed: "",
    struggles: "",
    targetCustomer: "",
    usp: "",
    goals: "",
    businessInfo: "",
  })

  // updates the fields state when user fills out the form below
  const handleFieldChange = (e) => {
    setAnswers({ ...answers, [e.target.name]: e.target.value })
  }

  // saving the questionnaire to the questionnaires collection in firestore db, then navigates to the profile
  const saveAnswers = () => {
    addDoc(collection(db, "questionnaires"), {
      address: answers.address,
      birthday: answers.birthday,
      website: answers.website,
      instagram: answers.instagram,
      twitter: answers.twitter,
      linkedin: answers.linkedin,
      youtube: answers.youtube,
      tiktok: answers.tiktok,
      worked: answers.worked,
      failed: answers.failed,
      struggles: answers.struggles,
      targetCustomer: answers.targetCustomer,
      usp: answers.usp,
      goals: answers.goals,
      userId: user.uid,
      businessInfo: answers.businessInfo,
    })
      .then(() => navigate("/profile"))
      .catch((error) => console.log(error))
  }

  return (
    <div className="qCon img-fluid">
      <h1 className="pageTitle d-lg-none text-center mb-3">Questionnaire</h1>
      <section className="d-flex flex-column align-items-center p-3">
        <h5 className="primaryTitle text-center">
          I am so excited you are part of the Acorn Marketing Academy and
          looking forward to getting to know you and your business over the
          coming months.
        </h5>
        <h5 className="primaryTitle text-center">
          Before we jump in, I want to know a bit more about you and what issues
          you are currently facing, so we can make the most out of our time.
        </h5>
        <section className="col col-xxl-6 ">
          <div className="my-3">
            <p className="secondaryText ">First a few details about you.</p>
            <section className="d-flex flex-column flex-lg-row  p-3 ">
              <label className="primaryTitle col-lg-4 px-2 mb-2">Name</label>
              <input
                disabled
                type={"text"}
                className="form-control"
                name={user.name}
                defaultValue={user.name}
                // onChange={handleFieldChange}
              />
            </section>
            <section className="d-flex flex-column flex-lg-row  p-3">
              <label className="primaryTitle col-lg-4 px-2 mb-2">
                Company Name
              </label>
              <input
                disabled
                type={"text"}
                className="form-control"
                name={user.company}
                defaultValue={user.company}
                // onChange={handleFieldChange}
              />
            </section>

            <NewInput
              name="address"
              handleFieldChange={handleFieldChange}
              label="Address"
            />
            <NewInput
              name="birthday"
              handleFieldChange={handleFieldChange}
              label="Birthday"
            />
            <NewInput
              name="website"
              handleFieldChange={handleFieldChange}
              label="Website"
            />
          </div>
          <div>
            <p className="secondaryText ">
              What social media do you use for business and what are your
              account profiles? - so I can check out what you're up to.
            </p>
            <NewInput
              name="instagram"
              handleFieldChange={handleFieldChange}
              label="Instagram"
            />
            <NewInput
              name="twitter"
              handleFieldChange={handleFieldChange}
              label="Twitter"
            />
            <NewInput
              name="linkedin"
              handleFieldChange={handleFieldChange}
              label="Linked-In"
            />
            <NewInput
              name="youtube"
              handleFieldChange={handleFieldChange}
              label="YouTube"
            />
            <NewInput
              name="tiktok"
              handleFieldChange={handleFieldChange}
              label="TikTok"
            />
          </div>

          <div className="mt-3">
            <p className="secondaryText mb-3">Business Information</p>
            <NewTextarea
              label="Tell me about your business, your products and services"
              handleFieldChange={handleFieldChange}
              name="businessInfo"
            />
          </div>

          <div className="mt-3">
            <p className="secondaryText mb-3">
              What marketing have your tried in the past?
            </p>
            <NewTextarea
              label="What has worked?"
              handleFieldChange={handleFieldChange}
              name="worked"
            />
            <NewTextarea
              label="What has failed?"
              handleFieldChange={handleFieldChange}
              name="failed"
            />
            <NewTextarea
              label="What are you struggling with at the moment, or what is the area
              you'd like to look at in more depth?"
              handleFieldChange={handleFieldChange}
              name="struggles"
            />
            <NewTextarea
              label="Can you tell me about your target customer?"
              handleFieldChange={handleFieldChange}
              name="targetCustomer"
            />
            <NewTextarea
              label="What are you unique selling points (USPs)?"
              handleFieldChange={handleFieldChange}
              name="usp"
            />
            <NewTextarea
              label="What would you like to achieve by working with me?"
              handleFieldChange={handleFieldChange}
              name="goals"
            />
          </div>

          <div className="d-flex justify-content-end">
            <button className="primaryBtn" onClick={saveAnswers}>
              Save
            </button>
          </div>
        </section>
      </section>
    </div>
  )
}
export default Questionnaire
