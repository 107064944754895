import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Link } from "react-router-dom"
import { useAuthContext } from "../../../hooks/useAuthContext"

import "./Footer.css"
export default function Footer() {
  const { user } = useAuthContext()

  function openInNewTab(url) {
    window.open(url, "_blank").focus()
  }
  return (
    <div className="footerCon d-flex flex-column flex-lg-row justify-content-center align-items-center">
      <div className="d-flex flex-column text-center text-lg-start col-9 col-lg-2 footerLinks">
        <h5 className="footerHeader mb-3">More to Explore</h5>
        <Link to={`./${user.uid}/questionnaire`}>Questionnaire</Link>
        <Link to={`./${user.uid}/actionplans`}>Action Plan</Link>
        <Link to={`./${user.uid}/checkin`}>Check In</Link>
        <Link to={`./${user.uid}/downloads`}>Downloads</Link>
        <Link to={`./${user.uid}/templates`}>Templates</Link>
        <Link to={`./${user.uid}/courses`}>Courses</Link>
        <Link to={`./${user.uid}/toptipvideos`}>Videos</Link>
      </div>
      <div className="footerAwards col-12 col-lg-3"></div>
      <div className="d-flex flex-column col col-lg-3 ">
        <h5 className="footerHeader text-center text-lg-end">Get In Touch</h5>

        <div className="d-flex flex-column text-center text-lg-end  footerContact">
          <p>
            t: 07751 212 662 <br /> e: lisa@acorn-marketing.co.uk
          </p>
        </div>

        <div className="footerIcon d-flex justify-content-center justify-content-lg-end mb-3">
          <FontAwesomeIcon
            icon={faFacebook}
            size="2x"
            className="m-2 footerIcon"
            onClick={() =>
              openInNewTab("https://www.facebook.com/Acornmarketingcumbria")
            }
          />

          <FontAwesomeIcon
            icon={faTwitter}
            size="2x"
            className="m-2 footerIcon"
            onClick={() => openInNewTab("https://twitter.com/Acorn_lisa")}
          />

          <FontAwesomeIcon
            icon={faPinterest}
            size="2x"
            className="m-2 footerIcon"
            onClick={() =>
              openInNewTab("https://www.pinterest.co.uk/acornmarketing1/")
            }
          />

          <FontAwesomeIcon
            icon={faInstagram}
            size="2x"
            className="m-2 footerIcon"
            onClick={() =>
              openInNewTab("https://www.instagram.com/acorn_marketing/")
            }
          />

          <FontAwesomeIcon
            icon={faLinkedin}
            size="2x"
            className="m-2 footerIcon"
            onClick={() =>
              openInNewTab(
                "https://www.linkedin.com/company/acorn-marketing-cumbria/"
              )
            }
          />
        </div>

        <div className="text-center text-lg-end footerBlurb">
          <div className="footerPolicy">
            <span
              onClick={() =>
                openInNewTab("https://acorn-marketing.co.uk/privacy-policy/")
              }
            >
              Privacy Policy
            </span>
            <span className="mx-1">|</span>
            <span
              onClick={() =>
                openInNewTab("https://acorn-marketing.co.uk/cookie-policy/")
              }
            >
              Cookie Policy
            </span>
          </div>
          <p>
            Acorn Marketing (Cumbria) Limited. <br /> Registered in England &
            Wales No: 08842950. <br /> Registered Office: Clint Mill, Corn
            Market, Penrith CA11 7HW
          </p>
        </div>
      </div>
    </div>
  )
}
