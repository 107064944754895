import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { db } from "../../../firebase/firebase"
import { query, where, collection, getDocs } from "firebase/firestore"
import AddCompany from "../addcompany/AddCompany"
import "./style.css"

function Dashboard() {
  const navigate = useNavigate()
  const [companies, setCompanies] = useState([])

  // after login the user is redirected to the Dashboard where on the irst render using a useEffect hook, the component calls getAllCompany function which gets all the invited/registered companies and sets the return array to the companies state as an array
  useEffect(() => {
    const getAllCompany = async () => {
      try {
        const q = query(collection(db, "users"), where("isAdmin", "==", false))
        const querySnapshot = await getDocs(q)
        const listOfCompanies = querySnapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id }
        })
        setCompanies(listOfCompanies)
      } catch (error) {
        console.log(error)
      }
    }

    getAllCompany()
  }, [])

  console.log(companies)
  // below SignOut and AddCompany component the companies array is being mapped through to render each company's name as a link which can direct the admin to the chosen companies profile when clicked.
  return (
    <div className="container py-5 d-flex flex-column  align-items-md-center">
      <h1 className="pageTitle d-lg-none text-center mb-3">Admin Dashboard</h1>
      <section className=" col-lg-6 my-2 py-3">
        <AddCompany />
      </section>
      <h2 className="primaryTitle p-3 text-center">Companies</h2>
      <div className="d-flex flex-column flex-md-row flex-md-wrap col-12 ">
        {companies?.map((company) => (
          <div key={company.id} className="col col-md-6 p-2">
            <div
              className=" companyItem "
              onClick={() =>
                navigate(`/company/${company.company}/${company.id}`)
              }
            >
              {company.company}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default Dashboard
