import { useEffect, useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import UserContext from "../../../contexts/userContext"
import { storage, db } from "../../../firebase/firebase"
import { collection, getDocs } from "firebase/firestore"
import { ref, getDownloadURL } from "firebase/storage"
import "./Templates.css"
import { useAuthContext } from "../../../hooks/useAuthContext"
import NavCard from "./cards/NavCard"

function Templates() {
  let navigate = useNavigate()
  const [templates, setTemplates] = useState()
  const { user } = useAuthContext()

  // if the user is not logged in useEffect redirects to the home page, otherwise it calls getAllTemplates function
  useEffect(() => {
    getAllTemplates()
  }, [])

  // gets all the templates from the database and sends back as an array which is set to be the templates state of the component
  const getAllTemplates = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "templates"))
      const listOfTemplatess = querySnapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id }
      })
      setTemplates(listOfTemplatess)
    } catch (error) {
      console.log(error)
    }
  }

  // downloading the file first a referene has to be set, using the id of the template, below when the component maps through the templates array, the button id is set to the templates id
  const downloadFile = (e) => {
    const gsReference = ref(storage, `templates/${e.target.id}`)

    getDownloadURL(gsReference)
      .then((url) => {
        window.open(url, "_blank")
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div className="templateCon">
      <h1 className="pageTitle d-lg-none text-center">Templates</h1>
      <div className="">
        <h4 className="primaryText text-center py-4">
          In this section you will find all the templates I use for my own, and
          my clients marketing. <br /> These should be really useful items for
          your to have in your own marketing toolbox.
        </h4>

        <div className="showCard d-flex flex-column flex-md-row flex-md-wrap justify-content-center p-3 gap-4 pb-5">
          {templates?.map((template) => (
            <NavCard>
              <h5 className="primaryTitle navCardName text-center">
                {template.name}
              </h5>
              <button
                onClick={downloadFile}
                className="primaryBtn"
                id={template.filename}
              >
                Download
              </button>
            </NavCard>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Templates
