import React from "react"

export default function NewTextarea({ handleFieldChange, label, name, value }) {
  return (
    <section className="d-flex flex-column flex-lg-row p-3">
      <label className="primaryTitle col-lg-4 px-2 mb-2">{label}</label>
      <textarea
        className="form-control"
        name={name}
        onChange={handleFieldChange}
        value={value}
      />
    </section>
  )
}
