import CheckinModal from "../../../modals/checkin/CheckinModal"
import { useEffect, useState } from "react"
import { db } from "../../../../../firebase/firebase"
import { doc, getDoc } from "firebase/firestore"
import "./style.css"

const Checkin = ({ checkinId }) => {
  const [showCheckin, setShowCheckin] = useState(false)
  const [checkin, setCheckin] = useState()

  //checkin component receives the id of the checkin prop from the parent component which is used by getCheckin function to get the details of the checkin, these details are set to be the components state (checkin) and this state is passed to the CheckinModal as props to display all the data
  useEffect(() => {
    getCheckin()
  }, [])

  const getCheckin = async () => {
    try {
      const docRef = doc(db, "checkins", checkinId)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        setCheckin(docSnap.data())
      } else {
        console.log("Can not find the company!")
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <tr>
        <td className="d-flex justify-content-between">
          <h5 className="primaryTitle">
            {new Date(checkin?.fields.date.toDate()).toDateString()}
          </h5>

          <button
            className={`btn ${!showCheckin ? "btn-primary" : "btn-danger"}`}
            onClick={() => setShowCheckin(!showCheckin)}
          >
            {showCheckin === false ? "View" : "Close"}
          </button>
        </td>
      </tr>
      {showCheckin && (
        <tr>
          <section>
            <p className="primaryText">
              What tasks did you do, or what did you accomplish?
            </p>
            <p>{checkin.fields.tasks}</p>
          </section>
          <section>
            <p className="primaryText">
              What didn't go so well this week, or where do you need support?
            </p>
            <p>{checkin.fields.need}</p>
          </section>
          <section>
            <p className="primaryText">
              What are your marketing plans for next week, what are you going to
              get done?
            </p>
            <p>{checkin.fields.plan}</p>
          </section>
        </tr>
      )}
    </>
  )
}

export default Checkin
