import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { db } from "../../../../firebase/firebase"
import { getDocs, query, where, collection } from "firebase/firestore"
import Checkin from "./checkin/Checkin"
import "./style.css"

function Checkins() {
  const companyId = useParams().companyId
  const [checkins, setCheckins] = useState()

  // on the first render getAllCheckins function is being called to get all the checkins the members have had so far, the array of checkins is being set to equal checkin
  useEffect(() => {
    getAllCheckins()
  }, [])

  const getAllCheckins = async () => {
    try {
      const q = query(
        collection(db, "checkins"),
        where("userId", "==", companyId)
      )
      const querySnapShot = await getDocs(q)

      const listOfCheckins = querySnapShot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id }
      })
      setCheckins(listOfCheckins)
    } catch (error) {
      console.log(error)
    }
  }

  // checkins array is being mapped through, and renders a Checkin component for each checkins, passing their ids as props
  return (
    <table className="table">
      <tbody>
        {checkins?.map((checkin) => (
          <Checkin checkinId={checkin.id} />
        ))}
      </tbody>
    </table>
  )
}
export default Checkins
