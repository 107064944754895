import welcome from "../../../assets/images/welcome.png"
import CheckInPage from "../checkinpage/CheckinPage"
import ViewActionPlans from "../viewactionplans/ViewActionPlans"
import "./Profile.css"
import { useAuthContext } from "../../../hooks/useAuthContext"
import { Link } from "react-router-dom"

function Profile() {
  const { user } = useAuthContext()
  // if the user is not logged in the component doesnt render


  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column flex-xl-row py-2 py-xl-5">
        <div className="welcomeMessage">
          <h1 className="mb-3">
            Hey {user.name.trim().split(/\s+/)[0]} - you're in!
          </h1>
          <p>
            Welcome inside the Acorn Marketing Academy - this is like the vault
            of all my insider material. If you can't find it here, it's probably
            not worth knowing. Have a browse around, watch the video tutorials,
            download my templates to use in your business, or even take one of
            my courses to learn even more about marketing and social media.
          </p>
          <p>
            <span className="welcomeSubTitle">What now?</span>
            <br />
            First of all, I want you to fill in the{" "}
            <Link to={`/${user.uid}/questionnaire`}>questionnaire</Link>, where
            I can get a really good sense of what you do and what marketing you
            are doing at the moment. Once we've had our initial kick-off call,
            we'll put all your actions into the action plan, so you can tick
            them off as we go. There is also the weekly check-in, where e can
            monitor how you are getting on and I can be on hand to answer any
            queries you may have.
          </p>
          <p>Are you ready to go? Let's do this!</p>
        </div>
        <div className="welcome-img-container col col-xl-8 mb-3">
          <img src={welcome} alt="Lisa with books" />
        </div>
      </div>
      <h1 className="pageTitle d-none d-md-block text-center mb-3">
        Action Plan
      </h1>
      <ViewActionPlans />
      <h1 className="pageTitle d-none d-md-block text-center mb-3">Check In</h1>
      <CheckInPage />
    </div>
  )
}

export default Profile
