import React from "react"
import { Link } from "react-router-dom"

import "./NavCard.css"

export default function NavCard({ children }) {
  return (
    <div className="d-flex flex-column align-items-center navCard gap-2 p-3 col col-xl-4 col-xxl-3 ">
      {children}
      <img
        width="60"
        height="60"
        src="https://acorn-marketing.co.uk/wp-content/uploads/2019/12/acorn-1.png"
        data-src="https://acorn-marketing.co.uk/wp-content/uploads/2019/12/acorn-1.png"
        class="attachment-full size-full ls-is-cached lazyloaded"
      />
    </div>
  )
}
