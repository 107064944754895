import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { db } from "../../../firebase/firebase"
import { getDoc, doc, updateDoc } from "firebase/firestore"

// ACCESSTOGGLE component is rendered in admin/company/companyprofile/CompanyProfile.js

function AccessToggle() {
  // to get the company id from the parameters, using useParams
  const companyId = useParams().companyId
  const [companyStatus, setCompanyStatus] = useState()

  // getAccessStatus function finds the user in the users database and sends back the value of the hasAccess key -> will be a boolean
  useEffect(() => {
    getAccessStatus()
  }, [])

  const getAccessStatus = async () => {
    try {
      const docRef = doc(db, "users", companyId)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        setCompanyStatus(docSnap.data().hasAccess)
      } else {
        console.log("Can not find the company!")
      }
    } catch (error) {
      console.log(error)
    }
  }

  // On the component first render, the useEffect function above is setting the state of companyStatus to the value of the hasAccess key on the database entry
  // This companyStatus state is being set to be the access button's id(below in the component)
  // The update function first finds the correct company in database, then gets the id of the clicked button
  // depending on the value of the id, sets the companyStatus state and the hasAccess key's value to the opposite then it was before button was pressed, false -> true or true -> false
  // This will result in two things
  // -> if the company had access before:
  // now their access has been revoked, now they can not log in and access their profile, and on the admin side the companyStatus is false, and the admin will see Access Status : Denied written on the profile
  // -> if the company havent had access before:
  // now their access is granted, they can log in to access their profile, and on the admin side the sompanyStatus is true, and the admin will see Access Status: Granted
  const update = async (e) => {
    const userRef = doc(db, "users", companyId)

    if (e.target.id === "false") {
      await updateDoc(userRef, {
        hasAccess: true,
      })
      setCompanyStatus(true)
    } else {
      await updateDoc(userRef, {
        hasAccess: false,
      })
      setCompanyStatus(false)
    }
  }

  return (
    <div className="mb-3">
      <div className="d-flex align-items-center">
        <h2 className="mb-3 primaryTitle me-5">Access Status:</h2>
        {companyStatus === true ? <h3> Granted</h3> : <h3> Denied</h3>}
      </div>
      <button
        className={`btn ${companyStatus ? "btn-danger" : "btn-success"} `}
        id={companyStatus?.toString()}
        onClick={update}
      >
        {companyStatus === true ? "Deny" : "Allow"} Access
      </button>
    </div>
  )
}

export default AccessToggle
