import { useState } from "react"
import { httpsCallable } from "firebase/functions"
import { functions, db, auth } from "../../../firebase/firebase"
import { setDoc, doc, updateDoc } from "firebase/firestore"
import "./style.css"
import { Form } from "react-bootstrap"
import { createUserWithEmailAndPassword } from "firebase/auth"
import { useAuthContext } from "../../../hooks/useAuthContext"
import { useNavigate } from "react-router-dom"

function AddCompany() {
  const [dataByUser, setDataByUser] = useState({
    companyName: "",
    name: "",
    email: "",
    password: "",
  })
  // alert state is being used to let admin know if inviting the company was successful or not
  const [alert, setAlert] = useState("")
  const navigate = useNavigate()
  const { dispatch } = useAuthContext()
  // handlefieldchange function is setting the state of companyData, when admin types in name and email
  const handleFieldChange = (event) => {
    setDataByUser({ ...dataByUser, [event.target.name]: event.target.value })
  }

  const handleRegister = async () => {
    //create user in firebase

    createUserWithEmailAndPassword(auth, dataByUser.email, dataByUser.password)
      .then((userCredentials) => {
        setDoc(doc(db, "users", userCredentials.user.uid), {
          company: dataByUser.companyName,
          name: dataByUser.name,
          email: dataByUser.email,
          isAdmin: false,
          hasAccess: true,
        })

        console.log("userCreated")
      })
      .catch((error) => console.log(error))
      .finally(() => {
        // dispatch({ type: "LOGOUT", payload: {} })
        navigate("/")
        // auth.signOut()
      })
  }

  return (
    <div className="d-flex flex-column justify-content-center ">
      {alert}
      <h2 className="primaryTitle">Add User</h2>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Company Name</Form.Label>
          <Form.Control
            type="text"
            name="companyName"
            placeholder="Enter company name..."
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            placeholder="Enter your name..."
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="Enter email address.."
            onChange={handleFieldChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            name="password"
            placeholder="Enter your password..."
            onChange={handleFieldChange}
          />
        </Form.Group>
      </Form>

      <div className="">
        <button className="primaryBtn" onClick={() => handleRegister()}>
          Add
        </button>
      </div>
    </div>
  )
}

export default AddCompany
