import { useLocation } from "react-router-dom"
import "./TopTipVideos.css"

import VideoDisplay from "../videoDisplay/VideoDisplay"
import { useState } from "react"
import CategoryListItem from "./CategoryListItem"

function TopTipVideos() {
  const catRoute = useLocation()

  const [category, setCategory] = useState(
    catRoute.state ? catRoute.state.category : "tutorial"
  )

  return (
    <div className="">
      <h1 className="pageTitle d-lg-none text-center">Videos</h1>
      <div className="p-3 d-flex justify-content-center gap-3 categoryList">
        <CategoryListItem
          title={"Social Media"}
          slug={"socialmedia"}
          setCategory={setCategory}
          category={category}
        />
        <CategoryListItem
          title={"Marketing"}
          slug={"marketing"}
          setCategory={setCategory}
          category={category}
        />
        <CategoryListItem
          title={"Email Marketing"}
          slug={"emailmarketing"}
          setCategory={setCategory}
          category={category}
        />
        <CategoryListItem
          title={"Digital Marketing"}
          slug={"digitalmarketing"}
          setCategory={setCategory}
          category={category}
        />
        <CategoryListItem
          title={"Tutorials"}
          slug={"tutorial"}
          setCategory={setCategory}
          category={category}
        />
        <CategoryListItem
          title={"Marketing Mastery"}
          slug={"masteryclass"}
          setCategory={setCategory}
          category={category}
        />
        <CategoryListItem
          title={"Mini Masterclass"}
          slug={"minimarketingclass"}
          setCategory={setCategory}
          category={category}
        />
      </div>
      <div className="videosBottomRow">
        <VideoDisplay category={category} />
      </div>
    </div>
  )
}

export default TopTipVideos
